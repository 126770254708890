import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AuthStore } from "./store/authStore";
import NavStore from "./store/navStore";
import { ProjectStore } from './store/projectStore';
import './index.less';


const root = ReactDOM.createRoot(document.getElementById('root'));
const navStore = new NavStore();

root.render(
  <React.StrictMode>
    <App authStore={AuthStore} navStore={navStore} projectStore={ProjectStore}/>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
