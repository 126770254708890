import { Navigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";

function RequiredAuth({ children, authStore }) {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    authStore.computeAuthSession().then(() => {
      setLoading(false);
    });
  }, [authStore]);
  if (loading) {
    return <div></div>;
  } else {
    return authStore.authenticated ? (
      children
    ) : (
      <Navigate to="/login" replace />
    );
  }
}

export default observer(RequiredAuth);
