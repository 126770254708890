import { makeAutoObservable, action } from "mobx";

export default class NavStore {
  selectedKey = "1";

  updateSelectedKey(selectedKey) {
    this.selectedKey = selectedKey;
  }

  constructor() {
    makeAutoObservable(this, {
      updateSelectedKey: action,
    });
  }
}
