import { makeAutoObservable, action, computed } from "mobx";
import { refreshOrGet, userSession } from "../components/security/cognito";

export class AuthStoreImpl {
  authenticated = false;
  session = null;
  roles = [];
  user = null;
  scopedUser = null;

  updateUser(user) {
    this.user = user;
  }
  updateScopeUser(user) {
    this.scopedUser = user;
  }

  setSession(session) {
    this.session = session;
    this.authenticated = true;
  }

  interceptorSetSession(session) {
    this.setSession(session)
  }

  async computeAuthSession() {
    if (this.authenticated && this.session != null) {
      try {
        this.session = await refreshOrGet(this.session);
        this.authenticated = true;
      } catch (e) {
        this.authenticated = false;
      }
    }

    try {
      const session = await userSession();
      this.setSession(session);
    } catch (e) {
      this.authenticated = false;
    }
  }

  get userRole() {
    try {
      return this.user.role;
    } catch {
      return null;
    }
  }

  constructor() {
    makeAutoObservable(this, {
      setSession: action,
      computeAuthSession: action,
      updateUser: action,
      updateScopeUser: action,
      userRole: computed,
      interceptorSetSession: action.bound
    });
  }
}

export const AuthStore = new AuthStoreImpl();
