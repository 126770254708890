import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import Projects from "../components/project/Projects";

import "./Home.css";


function Home({ authStore, navStore, projectStore }) {
  useEffect(() => {
    navStore.updateSelectedKey("4");
  }, [navStore]);

  return (
    <div className="Home">
      <Projects authStore={authStore} navStore={navStore} projectStore={projectStore}></Projects>
    </div>
  );
}

export default observer(Home);
