import { makeAutoObservable, action } from "mobx";

export class ProjectStoreImpl {
  project = null;
  projects = [];
  url = null;
  urls = [];
  configLanguages = [];

  updateProject(project) {
    this.project = project;
  }
  updateProjects(projects) {
    this.projects = projects;
  }

  setUrl(url) {
    this.url = url;
  }
  setUrls(urls) {
    this.urls = urls;
  }

  setConfigLaguages(languages) {
    this.configLanguages = languages;
  }

  constructor() {
    makeAutoObservable(this, {
      updateProject: action,
      updateProjects: action,
      setUrl: action,
      setUrls: action,
      setTexts: action,
      setConfigLaguages: action,
    });
  }
}

export const ProjectStore = new ProjectStoreImpl();
